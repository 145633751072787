import { Button, Card } from "antd";
import { ColumnProps } from "antd/lib/table";
import { navigate } from "gatsby";
import idx from "idx";
import React, { FC, useCallback, useMemo, useState } from "react";
import GET_PRODUCT_LIST from "./getProductList.graphql";
import {
  GetProductList,
  GetProductList_products_items as Product,
  GetProductListVariables,
} from "./types/GetProductList";
import Authorize from "components/authorize";
import GraphQLTable, {
  creationTime,
  lastModificationTime,
  useColumnSearch,
} from "components/graphQLTable";
import { ImportProductsModal } from "components/import";
import MainLayout from "components/layouts/main";
import routes from "routes";

const ProductList: FC = () => {
  const [importModalVisible, setImportModalVisible] = useState(false);

  const showImportModal = useCallback(() => setImportModalVisible(true), [
    setImportModalVisible,
  ]);

  const hideImportModal = useCallback(() => setImportModalVisible(false), [
    setImportModalVisible,
  ]);

  const handleProductEdit = useCallback(
    (id: string) => () => navigate(routes.productEditToPath({ id })),
    []
  );

  const { createColumn: createVendorCodeColumn } = useColumnSearch<Product>(
    "vendorCode"
  );

  const { createColumn: createNameColumn } = useColumnSearch<Product>("name", {
    dataSearchIndex: "normalizedName",
    useDefaultNormalizer: true,
  });

  const columns = useMemo<Array<ColumnProps<Product>>>(
    () => [
      {
        align: "center",
        dataIndex: "id",
        sorter: true,
        title: "#",
        width: "60px",
      },
      {
        dataIndex: "image",
        title: "Картинка",
        width: "96px",
        render: (_, { images }) => {
          const image = idx(images, x => x[0].image);
          return image ? (
            <Card
              bodyStyle={{ padding: 0 }}
              cover={
                <img
                  src={image.url}
                  alt={image.key}
                  style={{ width: "64px", height: "64px" }}
                />
              }
            />
          ) : null;
        },
      },
      createVendorCodeColumn({ title: "Артикул", sorter: true }),
      createNameColumn({ title: "Наименование", sorter: true }),
      creationTime,
      lastModificationTime,
      {
        key: "action",
        render: (_text, { id }) => (
          <Button
            ghost={true}
            icon="edit"
            size="small"
            type="primary"
            onClick={handleProductEdit(`${id}`)}
          >
            Ред.
          </Button>
        ),
      },
    ],
    [createNameColumn, createVendorCodeColumn, handleProductEdit]
  );

  const getDataSource = useCallback(
    (data?: GetProductList) => idx(data, x => x.products),
    []
  );

  return (
    <Authorize>
      <MainLayout
        title="Продукты"
        extra={
          <Button icon="import" onClick={showImportModal}>
            Импорт
          </Button>
        }
      >
        <GraphQLTable<Product, GetProductList, GetProductListVariables>
          columns={columns}
          query={GET_PRODUCT_LIST}
          getDataSource={getDataSource}
        />
        <ImportProductsModal
          visible={importModalVisible}
          onCancel={hideImportModal}
        />
      </MainLayout>
    </Authorize>
  );
};

export default ProductList;
